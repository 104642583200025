

















































































import {
  reactive,
  computed,
  onBeforeMount,
  ref,
  watch,
  defineComponent,
  PropType,
} from '@vue/composition-api'
import { usePromiseLazy } from 'vue-composable'

import { useRouter } from '@galileo/composables/useRouter'

import { usePaymentInfo } from '@galileo/components/Views/PaymentMethod/usePaymentHelper'
import { removeDiacritics } from '@galileo/utilities/diacritics-convertor.utility'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton.vue'

import {
  SEGMENT_EVENTS,
  SEGMENT_LOCATIONS,
  SEGMENT_PAYMENT_METHOD_TYPES,
  SEGMENT_PAYMENT_CARD_STATUS,
} from '@galileo/constants/segmentAnalytics'

import AddressFieldsSwitcher from '@galileo/components/Views/PaymentMethod/BillingAddressCases/AddressFieldsSwitcher.vue'
import Address from '@galileo/models/Address/address'
import AddressModel from '@galileo/models/Address/BillingAddresses/AddressModel'

import { translit } from '@galileo/utilities/nonlatin-latin-convertor.utility'
import { extractRegexMaxLength } from '@galileo/utilities/regex.utility'

//@ts-ignore
import { IconClose } from '@oen.web.vue2/icons'

import GalileoAddressPicker from './BillingAddressCases/GalileoAddressPicker.vue'
import {
  useMediaQuery,
  useVModel,
  AppModal,
  useAlert,
  AppModalFooter,
  AppButton,
  AppModalHeader,
  AppSpinnerBig,
  AppBackButton,
  AppInputCountry,
  AppIcon,
  AppInputCheckbox,
  AppCard,
} from '@oen.web.vue2/ui'

import {
  useAppStore,
  useCountriesStore,
  useAnalyticsStore,
  usePaymentsStore,
  useI18nStore,
  useAuthStore,
  useSessionStore,
  useCardAccountStore,
} from '@galileo/stores'

import { PaymentAccount } from '@galileo/models/payments/interfaces/paymentsTypes'
import { PAYMENT_METHODS } from '@galileo/constants/sendMoneyFlow.const'
import { truncateString } from '@galileo/utilities/string-utils'
import { getCorrectedString } from '@galileo/utilities/sanitizers/sanitizeString'
import { CardField } from '@galileo/models/CardAccount/CardSettings'

export default defineComponent({
  components: {
    AppModalHeader,
    AppButton,
    AppModal,
    AppModalFooter,
    AppSpinnerBig,
    AppBackButton,
    AppInputCountry,
    AddressFieldsSwitcher,
    XeBackButton,
    AppIcon,
    IconClose,
    AppInputCheckbox,
    AppCard,
    GalileoAddressPicker,
  },
  // emits: ['input'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    storedAddress: {
      type: Address as PropType<Address>,
      default: new Address(),
      required: false,
    },
    isNewCard: {
      type: Boolean,
      required: false,
      default: false,
    },
    editCardTitle: {
      type: String,
      required: true,
      default: '',
    },
  },

  setup(props: any, { emit }: any) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const cardAccount = useCardAccountStore()
    const router = useRouter()
    const authStore = useAuthStore()
    const countriesStore = useCountriesStore()
    const paymentsStore = usePaymentsStore()
    const appStore = useAppStore()
    const analyticsStore = useAnalyticsStore()
    const sessionStore = useSessionStore()
    const { add } = useAlert()
    const countriesList = countriesStore.getCountries

    const loading = ref<boolean>(false)
    //No need for now
    const countries = ref()
    const cardSetting = ref({ cardBillingCountries: [] })
    const filteredCountries = ref()
    const sameAsResidentialAddress = ref(false)
    const selectedCardAddress = ref<AddressModel>(new AddressModel())

    const paymentAccount = computed(
      (): PaymentAccount => paymentsStore.selectedPaymentMethod as PaymentAccount
    )

    const { isCard } = usePaymentInfo(paymentAccount.value) //true/false

    const close = () => {
      router.toParentRoute()
    }

    const {
      loading: loadingPaymentAccount,
      exec: editPaymentAccount,
      error: errorPaymentAccount,
      result: resultPaymentAccount,
    } = usePromiseLazy((args) => paymentsStore.updateCard(paymentAccount.value, args))

    const billingAddress = ref<any>({})
    const isBillingAddressValid = ref(false)
    const userAddressModel = ref(new AddressModel())

    let truncationCharCount = 0

    // we fill in the billing address information in here
    onBeforeMount(async () => {
      loading.value = true

      analyticsStore.track({
        event: SEGMENT_EVENTS.EDIT_CARD_BILLING_ADDRESS_STARTED,
        traits: {
          paymentType: paymentAccount.value.isDebitCard
            ? PAYMENT_METHODS.DEBIT_CARD
            : PAYMENT_METHODS.CREDIT_CARD,
          cardBillingCountry: paymentAccount.value.country,
          location: SEGMENT_LOCATIONS.ACCOUNT,
          brand: sessionStore.brand,
        },
      })

      //if the country is not defined, means it is newly created and we get the country fro mthe user profile
      if (!paymentAccount.value.country) {
        let user = authStore.userProfile
        paymentAccount.value.country = user.country
      }

      mapBillingAddressFromSelectedCard()
      loading.value = false
    })

    const mapBillingAddressFromSelectedCard = () => {
      userAddressModel.value = new AddressModel(
        props.storedAddress.street,
        '',
        '',
        props.storedAddress.city,
        props.storedAddress.state,
        props.storedAddress.postalCode,
        props.storedAddress.country
      )
    }

    const mapAddressModelFromSelectedCardAddress = (country: string = '') => {
      userAddressModel.value = new AddressModel(
        paymentAccount.value.billingStreet,
        paymentAccount.value.billingStreetLine2 ?? '',
        '',
        paymentAccount.value.billingCity,
        paymentAccount.value.billingState,
        paymentAccount.value.billingPostalCode,
        paymentAccount.value.country ?? country
      )
    }

    const mapAddressModelFromUserAddress = () => {
      userAddressModel.value = useAuthStore().getUserAddress()
    }

    const sanitizeAddressFields = (addressString: string) => {
      if (addressString) {
        return removeDiacritics(translit(addressString))
      }
      return ''
    }

    const mapToBillingAddress = () => {
      //#region sanitisation for address line 1
      let maxAddressLength = 60
      const cardFields = cardAccount.cardSettings?.cardFields

      if (cardFields) {
        const maxAddressLengthRegex = cardFields.find(
          (item: CardField) => item.name === 'BillToAddressLine1'
        )?.regEx

        if (maxAddressLengthRegex) {
          maxAddressLength = extractRegexMaxLength(maxAddressLengthRegex) ?? maxAddressLength
        }
      }

      const newAddressLine1 = truncateString(
        getCorrectedString(userAddressModel.value.line1),
        maxAddressLength
      )
      //#endregion

      const newAddressLine2 = sanitizeAddressFields(userAddressModel.value.line2)
      const newAddressLine3 = sanitizeAddressFields(userAddressModel.value.line3)
      const newAddressCountry = sanitizeAddressFields(userAddressModel.value.countryCode)
      const newAddressState = sanitizeAddressFields(userAddressModel.value.state)
      const newAddressCity = sanitizeAddressFields(userAddressModel.value.place)
      const newAddressPostalCode = sanitizeAddressFields(userAddressModel.value.postCode)

      billingAddress.value = {
        billingAddress: newAddressLine1,
        billingAddress2: newAddressLine2,
        billingAddress3: newAddressLine3,
        billingCountry: newAddressCountry,
        billingCity: newAddressCity,
        billingPostalCode: newAddressPostalCode,
        billingState: newAddressState,
      }
    }

    watch(sameAsResidentialAddress, async (newVal: boolean) => {
      let paymentMethodType = SEGMENT_PAYMENT_METHOD_TYPES.DEBIT

      if (paymentAccount.value.isDebitCard) {
        paymentMethodType = SEGMENT_PAYMENT_METHOD_TYPES.DEBIT
      } else {
        paymentMethodType = SEGMENT_PAYMENT_METHOD_TYPES.CREDIT
      }

      if (newVal) {
        isBillingAddressValid.value = true
        mapAddressModelFromUserAddress()

        analyticsStore.track({
          event: SEGMENT_EVENTS.CARD_BILLING_ADDRESS_SAME_AS_RESIDENTIAL,
          traits: {
            paymentType: paymentMethodType,
            cardBillingCountry: paymentAccount.value.country,
            location: SEGMENT_LOCATIONS.ACCOUNT,
            brand: sessionStore.brand,
          },
        })
      } else {
        analyticsStore.track({
          event: SEGMENT_EVENTS.CARD_BILLING_ADDRESS_NOT_SAME_AS_RESIDENTIAL,
          traits: {
            paymentType: paymentMethodType,
            cardBillingCountry: paymentAccount.value.country,
            location: SEGMENT_LOCATIONS.ACCOUNT,
            brand: sessionStore.brand,
          },
        })
        mapAddressModelFromSelectedCardAddress(selectedCardAddress.value.countryCode)
      }
    })

    const isValid = (valid: boolean) => {
      isBillingAddressValid.value = valid
    }

    const submit = async () => {
      let analyticsTraits

      mapToBillingAddress()

      if (isCard.value) {
        if (paymentAccount.value.isDebitCard) {
          analyticsTraits = {
            paymentMethod: SEGMENT_PAYMENT_METHOD_TYPES.DEBIT,
          }
        } else {
          analyticsTraits = {
            paymentMethod: SEGMENT_PAYMENT_METHOD_TYPES.CREDIT,
          }
        }

        let paymentMethodStatus

        if (paymentAccount.value.isExpired) {
          paymentMethodStatus = SEGMENT_PAYMENT_CARD_STATUS.EXPIRED
        } else if (paymentAccount.value.cardState === SEGMENT_PAYMENT_CARD_STATUS.ACTIVE) {
          paymentMethodStatus = SEGMENT_PAYMENT_CARD_STATUS.ACTIVE
        } else {
          paymentMethodStatus = SEGMENT_PAYMENT_CARD_STATUS.INVALID
        }

        analyticsTraits = {
          paymentMethodStatus,
          ...analyticsTraits,
        }
      } else {
        analyticsTraits = {
          paymentMethod: SEGMENT_PAYMENT_METHOD_TYPES.BANK_ACCOUNT,
        }
      }

      //we store the line 2 as line 1 as the backend doesn't support 2 lines for address
      if (billingAddress.value.billingAddress2) {
        billingAddress.value.billingAddress = `${billingAddress.value.billingAddress}, ${billingAddress.value.billingAddress2}`
        billingAddress.value.billingAddress2 = ''
      }

      if (truncationCharCount > 0) {
        billingAddress.value.billingAddress = billingAddress.value.billingAddress.slice(
          0,
          truncationCharCount
        )
      }

      if (!props.isNewCard) {
        await editPaymentAccount({
          ...paymentAccount.value,
          ...billingAddress.value,
          billingStreet: billingAddress.value.billingAddress,
        })
      } else {
        emit('onSave', billingAddress.value)
        analyticsStore.track({
          event: SEGMENT_EVENTS.PAYMENT_METHOD_UPDATED,
          traits: {
            location: SEGMENT_LOCATIONS.ACCOUNT,
            isBillingAddressSameAsResidentialAddress: sameAsResidentialAddress.value,
            isNew: props.isNewCard,
            ...analyticsTraits,
          },
        })

        return
      }

      if (errorPaymentAccount.value) {
        const errorMessage = errorPaymentAccount.value.friendlyMessage
          ? errorPaymentAccount.value.friendlyMessage
          : $t('ComponentBillingAddressModal.ErrorUpdate').value

        const msgBoxError = {
          title: $t('ComponentBillingAddressModal.ErrorUpdateTitle').value,
          text: errorMessage,
        }

        appStore.logException(
          'Exception during adding card',
          errorMessage ?? 'Exception during updating card billing address'
        )
        appStore.messageBoxGenericOk(msgBoxError.title, msgBoxError.text)
      }

      if (resultPaymentAccount.value) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.PAYMENT_METHOD_UPDATED,
          traits: {
            location: SEGMENT_LOCATIONS.ACCOUNT,
            isBillingAddressSameAsResidentialAddress: sameAsResidentialAddress.value,
            isNew: props.isNewCard,
            ...analyticsTraits,
          },
        })

        emit('onSave', billingAddress.value)

        add($t('ComponentBillingAddressModal.AlertSuccess').value)

        return
      }
    }

    function getCountry(country: string) {
      let result = countriesList.find((cty: any) => cty.value === country)

      if (result) {
        return result?.text
      }
    }

    const generateAddressString = computed(() => {
      const address = userAddressModel.value
      return `
      <span>${address.line1}</span><br/>
      <span>${address.place}, ${address.state} ${address.postCode}</span><br/>
      <span>${getCountry(address.countryCode)}</span>
      `
    })

    // Segment event that triggers when user starts typing the address
    const onSelectAddress = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.EDIT_CARD_BILLING_ADDRESS_SELECTED,
        traits: {
          paymentType: paymentAccount.value.cardType,
          cardBillingCountry: paymentAccount.value.country,
          location: SEGMENT_LOCATIONS.ACCOUNT,
          brand: sessionStore.brand,
        },
      })
    }

    // Segment event that triggers when user starts typing the address
    const onManualAddressSelect = () => {
      let paymentMethodType = SEGMENT_PAYMENT_METHOD_TYPES.DEBIT

      if (paymentAccount.value.isDebitCard) {
        paymentMethodType = SEGMENT_PAYMENT_METHOD_TYPES.DEBIT
      } else {
        paymentMethodType = SEGMENT_PAYMENT_METHOD_TYPES.CREDIT
      }

      analyticsStore.track({
        event: SEGMENT_EVENTS.EDIT_CARD_BILLING_ADDRESS_MANUALLY,
        traits: {
          paymentType: paymentMethodType,
          cardBillingCountry: paymentAccount.value.country,
          location: SEGMENT_LOCATIONS.ACCOUNT,
          brand: sessionStore.brand,
        },
      })
    }

    return {
      userAddressModel,
      model,
      billingAddress,
      close,
      mq: reactive(useMediaQuery()),
      submit,
      loading,
      loadingPaymentAccount,
      $t,
      countries,
      cardSetting,
      filteredCountries,
      isValid,
      isBillingAddressValid,
      sameAsResidentialAddress,
      generateAddressString,
      selectedCardAddress,
      onSelectAddress,
      onManualAddressSelect,
    }
  },
})
